:root {
  --main: #4b19e6;
  --secondary: #ff6d00;
  --light: #f1f1f1;
  --dark: #161616;
}

::selection {
  background-color: var(--main);
  color: var(--light);
}

::-moz-selection {
  background-color: var(--main);
  color: var(--light);
}

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  background-color: var(--main);
}

body {
  margin: 0;
  padding: 0;
  width: calc(100vw - 1rem);
  height: fit-content;
  min-height: calc(100vh - 1rem);
  background-color: var(--light);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: .5rem solid var(--main);
  border-radius: 25px;
}

a, span, p, h1, h2, h3, h4, h5, h6, ul, li, button, input, label {
  text-decoration: none;
  list-style: none;
  border: none;
  outline: none;
  color: var(--dark);
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  padding: 2rem 0;
  color: var(--main);
}

.main {
  position: relative;
}

.link-container {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 50px;
  height: 50px;
  background-color: var(--main);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light);
}

.link-container:hover {
  cursor: pointer;
}

.link-container i {
  font-size: 1.5rem;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin: 0 auto;
  padding: 2rem 0;
}

/* Card */

.card {
  display: flex;
  flex-direction: column;
  background-color: var(--light);
  border-radius: 50%;
  box-shadow: 0 0px 9px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  text-align: center;
  width: 200px;
  max-width: 45%;
  height: auto;
  justify-self: stretch;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 4%;
  aspect-ratio: 1/1;
}

/* CityCard */

.city-card {
  background-color: var(--light);
  border-radius: 8px;
  border-left: .2em solid var(--light);
  border-right: .2em solid var(--light);
  box-shadow: 0 0px 9px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  text-align: center;
  width: 200px;
  max-width: calc(50% - 25px);
  height: auto;
  justify-self: stretch;
  margin: 17px 9px;
}

.hover-main:hover {
  background-color: var(--main);
  border-color: var(--main);
}

.hover-main:hover h2 {
  color: var(--light);
}

.city-card:hover {
  cursor: pointer;
}

.city-card h2 {
  font-size: 1.2rem;
}

.city-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  margin-bottom: 0;
  aspect-ratio: 1/1;
}

.city-card-links {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.city-card-links a {
  color: var(--dark);
  margin: 0;
  padding: 1rem;
}

.city-card-links a i {
  font-size: 1.2rem;
}

.city-card-links a:hover {
  color: var(--main);
}

.city-card-links a:hover i {
  color: var(--main);
}

.city-card-links-line {
  justify-content: start !important;
  width: 600px;
  max-width: 100%;
  margin: 1rem auto;
  text-align: left !important;
}

.city-card-links-line a {
  padding: .5rem !important;
}

.city-card-links-line i {
  margin-right: .4rem;
}

/* Place */

.place {
  text-align: center;
  align-items: center;
  padding-top: 4rem;
}

.place-image {
  height: auto;
  object-fit: cover;
  object-position: center;
  margin: 0 auto !important;
  align-items: center;
  align-self:center;
  justify-content: center;
  justify-self: center;
  aspect-ratio: 16/9;
}

@media only screen and (max-width: 600px) {
  .place-image {
    width: 100%;
  }

  .place p {
    width: 95%;
  }

  .city-card h2 {
    min-height: 75px;
  }
}

@media only screen and (min-width: 600px) {
  .place-image {
    width: 600px;
    border-radius: 8px;
  }

  .place p {
    width: 584px;
  }

  .city-card h2 {
    min-height: 50px;
  }
}

.place p {
  padding: 1rem 0;
  font-size: 1rem;
  text-align: justify;
  margin: 0 auto;
}